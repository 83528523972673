import * as React from 'react';
import { checkSitePassword } from './src/lib/utils'
import PasswordProtect from './src/components/password-protect'
import "./src/css/animation.css"
// import "@fontsource/heading-font" // example font import

export const wrapPageElement = () => {
  if( checkSitePassword() ) {
    return
  }

  return <PasswordProtect />
};
